<template>
    <div>
        <button class="btn btn-link" @click="()=>dataObject.createNew()" >{{$t('Add Action / Comment')}}</button>
        
        <template v-for="item in dataObject.data.filter(x=>x.ParentComment_ID === null)">
            <Comment :item="item" :dataObject="dataObject" :personsViewName="personsViewName" :personsWhereClause="personsWhereClause" class="ms-2"/>
            
        </template>
    </div>

</template>
<script setup lang="ts">
    import {ref,computed} from 'vue';

    import Utils from 'o365.modules.utils.js'
    import Comment from 'o365.appComponent.Comments.Comment.vue'

    const props = defineProps({
        dataObject:{
            required:true
        },
        personsViewName: {
            type: String,
            required: false,
        },
        personsWhereClause: {
            type: String,
            required: false,
        }
    })

    props.dataObject.load();

</script>